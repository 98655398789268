import { FC } from 'react';

import BodyText from 'components/atoms/BodyText';
import { ISectionTitleProps } from 'components/molecules/SectionTitle/types';
import { colors } from 'styles/variables.module';
import {
  HeadingCategoryStyleWrapper,
  SectionTitleStyleWrapper
} from './styles';
import ShowElement from 'components/atoms/ShowElement';
import DisplayText from 'components/atoms/DisplayText';

const SectionTitle: FC<ISectionTitleProps> = ({
  title,
  subTitle,
  isFromMainPage = false
}) => {
  return (
    <SectionTitleStyleWrapper>
      <HeadingCategoryStyleWrapper
        size="large"
        level={isFromMainPage ? 2 : 1}
        fontWeight="bold"
        text={title}
      />

      <ShowElement
        isShow={isFromMainPage}
        show={
          <DisplayText
            size="small"
            level={2}
            color={colors.gray_8}
            fontWeight="regular"
            text={subTitle}
          />
        }
      >
        <BodyText color={colors.gray_8} size="small" text={subTitle} />
      </ShowElement>
    </SectionTitleStyleWrapper>
  );
};

export default SectionTitle;
