import BaseApiHandler from 'infrastructure/apiHandlers/baseApiHandler';
import {
  EndPointTypes,
  IApplicationsRequestModel,
  IPopularAppsRequestModel,
  ISearchApplicationsParams
} from './types';
import {
  IApplicationsApiResponseModel,
  IApplicationByAliasApiResponseModel,
  IApplicationReviewResponseModel,
  IApplicationReviewRequestModel,
  IAddApplicationReviewResponseModel,
  IUpdateApplicationReviewResponseModel,
  IDeleteApplicationReviewResponseModel,
  IApplicationRateCountResponseModel,
  IMostHelpfulAndUnHelpfulReviewCountsResponseModel,
  IPlanByAppIdApiResponseModel,
  IPermissionsResponseModel,
  IApplicationReviewsResponse,
  IApplicationByAliasMetaDataApiResponse,
  IApplicationsItemsModel
} from 'common/globalTypes/interfaces/apiResponseModel';
import {
  IApplicationByAliasRequestModel,
  IApplicationRateCountRequestModel,
  IDeleteReviewRequestModel,
  IHelpfulAndUnhelpfulRequestModel,
  IDeleteVoteRequestModel,
  IApplicationByAliasMetaDataRequestModel,
  IMostHelpfulAndUnHelpfulReviewCountsRequestModel,
  IPermissionsRequestModel
} from 'common/globalTypes/interfaces/apiRequestModel';
import {
  BackendResponseModelTypes,
  HeadersEnum
} from 'common/globalTypes/enums/http';
import langConvertByCountryCode from 'utils/helpers/langConvertByCountryCode';
import { DEFAULT_LANG } from 'common/constants/common';
import { PureNullable } from 'common/globalTypes/utilityTypes/common';

class ApplicationsApiHandler extends BaseApiHandler {
  constructor() {
    super();
  }

  public async getApplications({
    lang,
    ...queryParams
  }: IApplicationsRequestModel) {
    return await this.request.applicationsFetcher.get<IApplicationsApiResponseModel>(
      {
        url: EndPointTypes.applicationsPrefix,
        queryParam: { ...queryParams },
        headerOptions: {
          [HeadersEnum.xCurrentLocale]: lang
        },
        next: { revalidate: 300 }
      }
    );
  }

  public async getApplicationByAlias({
    alias,
    projectId
  }: IApplicationByAliasRequestModel) {
    const queryParam = projectId && { projectId };

    return await this.request.applicationsFetcher.get<IApplicationByAliasApiResponseModel>(
      {
        url: `${EndPointTypes.applicationsPrefix}/${EndPointTypes.byAlias}/${alias}`,
        queryParam: { ...queryParam }
      }
    );
  }

  public async getPlanByAppId(applicationId: string, projectId: number) {
    return await this.request.accountsFetcher.get<IPlanByAppIdApiResponseModel>(
      {
        url: `${EndPointTypes.api}/${EndPointTypes.applicationsPrefix}/${applicationId}/${EndPointTypes.currentPlan}/${projectId}`,
        responseModel: BackendResponseModelTypes.WithNoDataProperty
      }
    );
  }
  public async getApplicationByAliasMetaDataAndJsonLd({
    alias,
    lang = DEFAULT_LANG
  }: IApplicationByAliasMetaDataRequestModel) {
    const language = langConvertByCountryCode(lang);

    return await this.request.applicationsFetcher.get<IApplicationByAliasMetaDataApiResponse>(
      {
        url: `${EndPointTypes.applicationsPrefix}/${EndPointTypes.byAlias}/${alias}/${EndPointTypes.applicationMetaData}`,
        headerOptions: {
          [HeadersEnum.xCurrentLocale]: language
        },
        hideErrorMessage: true
      }
    );
  }

  public async searchApplication(
    searchTerm: string,
    queryParam: ISearchApplicationsParams
  ) {
    return await this.request.applicationsFetcher.get<IApplicationsApiResponseModel>(
      {
        url: `${EndPointTypes.searchApplicationPrefix}`,
        queryParam: {
          searchTerm,
          ...queryParam
        }
      }
    );
  }

  public async addReview({ ...review }: IApplicationReviewRequestModel) {
    return await this.request.applicationsFetcher.post<IAddApplicationReviewResponseModel>(
      {
        url: `${EndPointTypes.addApplicationReview}`,
        data: review
      }
    );
  }

  public async updateReview(review: IApplicationReviewRequestModel) {
    return await this.request.applicationsFetcher.patch<IUpdateApplicationReviewResponseModel>(
      {
        url: `${EndPointTypes.editApplicationReview}/${review.reviewId}`,
        data: review
      }
    );
  }

  public async deleteReview({ reviewId }: IDeleteReviewRequestModel) {
    return await this.request.applicationsFetcher.delete<IDeleteApplicationReviewResponseModel>(
      {
        url: `${EndPointTypes.deleteApplicationReview}/${reviewId}`
      }
    );
  }

  public async applicationReviews({ ...queryParams }) {
    const { applicationId, ...queryParamsRest } = queryParams;

    return await this.request.applicationsFetcher.get<IApplicationReviewResponseModel>(
      {
        url: `${EndPointTypes.applicationsPrefix}/${applicationId}/${EndPointTypes.applicationReviews}`,
        queryParam: { ...queryParamsRest }
      }
    );
  }

  public async applicationReviewById(reviewId: PureNullable<string>) {
    return await this.request.applicationsFetcher.get<IApplicationReviewsResponse>(
      {
        url: `${EndPointTypes.singleApplicationReview}/${reviewId}`,
        responseModel: BackendResponseModelTypes.WithData
      }
    );
  }

  public async helpfulOrUnhelpfulReview({
    reviewId,
    helpfulOrUnhelpful: action
  }: IHelpfulAndUnhelpfulRequestModel) {
    return await this.request.applicationsFetcher.post<IUpdateApplicationReviewResponseModel>(
      {
        url: `${EndPointTypes.helpfulOrUnhelpfulApplicationReview}/${reviewId}/vote`,
        data: {
          action
        }
      }
    );
  }

  public async deleteVoteReview({ reviewId }: IDeleteVoteRequestModel) {
    return await this.request.applicationsFetcher.delete<IUpdateApplicationReviewResponseModel>(
      {
        url: `${EndPointTypes.helpfulOrUnhelpfulApplicationReview}/${reviewId}/vote`
      }
    );
  }

  public async mostHelpfulAndUnHelpfulReviewCounts({
    applicationId
  }: IMostHelpfulAndUnHelpfulReviewCountsRequestModel) {
    return await this.request.applicationsFetcher.get<
      IMostHelpfulAndUnHelpfulReviewCountsResponseModel[]
    >({
      url: `${EndPointTypes.applicationsPrefix}/${applicationId}/${EndPointTypes.reviewCounts}`
    });
  }

  public async applicationRateCount({
    applicationId
  }: IApplicationRateCountRequestModel) {
    return await this.request.applicationsFetcher.get<
      IApplicationRateCountResponseModel[]
    >({
      url: `${EndPointTypes.applicationsPrefix}/${applicationId}/${EndPointTypes.applicationRateCount}`
    });
  }

  public async getPermissionsByAppId({ clientId }: IPermissionsRequestModel) {
    return await this.request.accountsFetcher.get<IPermissionsResponseModel>({
      url: `${EndPointTypes.permissions}/${clientId}`,
      responseModel: BackendResponseModelTypes.WithNoDataProperty
    });
  }

  public async getPopularApps({ lang }: IPopularAppsRequestModel) {
    return await this.request.applicationsFetcher.get<
      IApplicationsItemsModel[]
    >({
      url: `${EndPointTypes.applicationsPrefix}/${EndPointTypes.pupularApps}`,
      headerOptions: {
        [HeadersEnum.xCurrentLocale]: lang
      }
    });
  }
}

const applicationsApiHandler = new ApplicationsApiHandler();

export default applicationsApiHandler;
